import { Navigate, useRoutes } from 'react-router-dom';

import LogoOnlyLayout from '../layout/only-logo-layout';
import Index from '../pages/app/index';
import { PurchasedCompletedPix } from '../pages/app/purchased-completed-pix';
import { PurchasedCompletedCreditCard } from '../pages/app/purchased-completed-creditcard';
// import { PixPayment } from '../pages/app/pix-payment';
// import_screen

const AppRoutes = () => {
  const AVAILABLE_ROUTES = {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { element: <Navigate to="/" replace /> },
      { path: '', element: <Index /> },
      { path: '/purchased-pix', element: <PurchasedCompletedPix /> },
      { path: '/purchased-card', element: <PurchasedCompletedCreditCard /> },
      // { path: '/pix-payment', element: <PixPayment /> }
    ]
  };

  return useRoutes([AVAILABLE_ROUTES]);
};

export default AppRoutes;