import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import reportWebVitals from './reportWebVitals';

const addFavicon = () => {
  const faviconPath = {
    'abrir.cadastromei.org': '/static/illustrations/favicon-cadastromei.ico',
    'abrir.meicontabil.com': '/static/illustrations/mei_contabil_icon_cor.ico',
    'abrir.portalmeumei.com': '/static/illustrations/portalmeumei-favicon.png',
    'abrir.aberturamei.org': '/static/illustrations/aberturamei_favicon.icon',
    'abrir.empreendedorcnpj.com.br': '/static/illustrations/empreendedorcnpj_icon.png',
    'abrir.cnpjmei.com.br': '/static/illustrations/cnpjmei_icon.png',
  }[window.location.hostname] || '/static/illustrations/favicon-cadastromei.ico'

  const link = document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  link.href = faviconPath;
  document.head.appendChild(link);
}

addFavicon();

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
