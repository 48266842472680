import { brStates } from "../constants/br-states.constants";
import { actingTypes, principalOcupationOptions } from "../constants/cnpj.constants";
import { declarationYears } from "../constants/declaration-years";

export namespace UserFormStepBuilder {
    export const form = {
        steps: [
            {
                key: 0,
                title: 'Dados Pessoais',
                sections: [
                    {
                        key: 'details',
                        label: 'Dados Pessoais',
                        inputs: [
                            {
                                label: 'Nome Completo*',
                                id: 'fullName',
                                type: 'text',
                                required: true,
                                placeholder: 'Digite seu nome completo',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                            },
                            {
                                label: 'Endereço de e-mail *',
                                id: 'email',
                                type: 'email',
                                required: true,
                                placeholder: 'Digite seu e-mail',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                            },
                            {
                                label: 'CPF *',
                                placeholder: '000.000.000-00',
                                id: 'cpf',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                                // mask: '999.999.999-99',
                            },
                            {
                                label: 'Número de Telefone (WhatsApp) *',
                                id: 'phone',
                                placeholder: '(00) 00000-0000',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                                mask: '(99) 99999-9999',
                            },
                          
                            {
                                label: 'RG / Identidade *',
                                id: 'rg',
                                type: 'number',
                                required: true,
                                placeholder: 'Digite o número do RG',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Órgão Emissor do RG *',
                                id: 'rgEmissor',
                                placeholder: '(Ex: SSP)',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Estado de Emissão *',
                                id: 'rgStateEmissor',
                                type: 'select',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: brStates,
                            },
                            {
                                label: 'Nome completo da Mãe *',
                                id: 'mothername',
                                type: 'text',
                                placeholder: 'Digite o nome completo da mãe',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                            },
                            {
                                label: 'Data de Nascimento *',
                                id: 'birthdate',
                                placeholder: '00/00/0000',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                                mask: '99/99/9999'
                            },
                        ]
                    },
                ]
            },
            {
                key: 1,
                title: 'Dados CNPJ',
                sections: [
                    {
                        key: 'cnpj_data',
                        label: 'Dados CNPJ',
                        inputs: [[
                            // {
                            //     label: 'Nome fantasia',
                            //     placeholder: 'Digite o nome da sua empresa',
                            //     id: 'fantasyName',
                            //     type: 'text',
                            //     required: true,
                            //     grid: {
                            //         xs: 12,
                            //         sm: 12,
                            //         md: 8,
                            //     },
                            // },
                            {
                                label: 'Valor do Capital Social',
                                placeholder: 'Ex: R$ 1000,00',
                                id: 'socialCapital',
                                type: 'text',
                                required: true,
                                currency: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Ocupação Principal da Empresa *',
                                placeholder: 'Selecione...',
                                id: 'principalOcupation',
                                required: true,
                                type: 'autocomplete',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: principalOcupationOptions
                            },
                            {
                                label: 'Escolha a Ocupação Secundária (até 15 opções)',
                                placeholder: 'Selecione...',
                                id: 'secondaryOcupations',
                                required: true,
                                isMultiple: true,
                                type: 'autocomplete',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: principalOcupationOptions
                            },
                            {
                                label: 'Forma de atuação *',
                                placeholder: 'Selecione...',
                                id: 'actingType',
                                required: true,
                                type: 'autocomplete',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: actingTypes
                            },
                        ], [{
                            label: 'CNPJ',
                            placeholder: '00.00.000/0000-0',
                            id: 'cnpj',
                            type: 'text',
                            required: true,
                            grid: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                            },
                        }], [{
                            label: 'CNPJ',
                            placeholder: '00.00.000/0000-0',
                            id: 'cnpj',
                            type: 'text',
                            required: true,
                            grid: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                            },
                            
                        }, {
                            label: 'Ano de declaração',
                            id: 'declaration_year',
                            type: 'select',
                            required: true,
                            grid: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                            },
                            options: declarationYears,
                        }, {
                            label: 'Faturamento Total do ano',
                            placeholder: '',
                            id: 'annual_billing',
                            type: 'text',
                            required: true,
                            currency: true,
                            grid: {
                                xs: 12,
                                sm: 12,
                                md: 6,
                            },
                        }], [{
                                label: 'CNPJ',
                                placeholder: '00.00.000/0000-0',
                                id: 'installmentCnpj',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 6,
                                },
                            }
                        ]],
                    },
                ]
            },
            {
                key: 2,
                title: 'Endereço',
                sections: [
                    {
                        key: 'personal_address',
                        label: 'Endereço Pessoal',
                        inputs: [
                            {
                                label: 'CEP',
                                id: 'zipcode',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                placeholder: '00000-000',
                                mask: '99999-999',
                            },
                            {
                                label: 'Cidade',
                                placeholder: 'Digite a sua cidade',
                                id: 'city',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Bairro',
                                placeholder: 'Digite o seu bairro',
                                id: 'district',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Endereço',
                                placeholder: 'Digite o seu endereço',
                                id: 'street',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Número',
                                id: 'number',
                                type: 'number',
                                required: true,
                                placeholder: 'Ex: 109',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Estado',
                                id: 'state',
                                type: 'select',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: brStates,
                            },
                            {
                                label: 'Complemento',
                                id: 'complement',
                                type: 'text',
                                required: false,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                },
                                placeholder: '(Ex: Perto da escola)'
                            },
                        ]
                    },
                    {
                        key: 'comercial_address',
                        label: 'Endereço Comercial',
                        inputs: [
                            {
                                label: 'CEP',
                                id: 'comercialAddressZipcode',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                placeholder: '00000-000',
                                mask: '99999-999',
                            },
                            {
                                label: 'Cidade',
                                placeholder: 'Digite a sua cidade',
                                id: 'comercialAddressCity',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Bairro',
                                placeholder: 'Digite o seu bairro',
                                id: 'comercialAddressDistrict',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Endereço',
                                placeholder: 'Digite o seu endereço',
                                id: 'comercialAddressStreet',
                                type: 'text',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Número',
                                id: 'comercialAddressNumber',
                                type: 'number',
                                required: true,
                                placeholder: 'Ex: 109',
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                            },
                            {
                                label: 'Estado',
                                id: 'comercialAddressState',
                                type: 'select',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 4,
                                },
                                options: brStates,
                            },
                            {
                                label: 'Complemento',
                                id: 'comercialAddressComplement',
                                type: 'text',
                                required: false,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                },
                                placeholder: '(Ex: Perto da escola)'
                            },
                        ]
                    }
                ]
            },
            {
                key: 4,
                title: 'Pagamento',
                sections: [
                    {
                        key: 'gov_access',
                        label: 'Acesso GOV.BR',
                        inputs: [
                            {
                                label: 'CPF',
                                placeholder: '000.000.000-00',
                                id: 'cpf',
                                type: 'email',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                },
                            },
                            {
                                label: 'Confirme a senha GOV.BR (BRASIL CIDADÃO)',
                                placeholder: 'Digite a sua senha de acesso gov.br',
                                id: 'govPassword',
                                type: 'password',
                                required: true,
                                grid: {
                                    xs: 12,
                                    sm: 12,
                                    md: 12,
                                },
                            },
                        ]
                    }
                ]
            },
            {
                key: 5,
                title: 'Dados CNPJ',
                sections: [{
                     key: 'cnpj_data',
                    label: 'Dados CNPJ',
                    inputs: [
                        {
                            label: 'Valor do Capital Social',
                            placeholder: 'Ex: R$ 1000,00',
                            id: 'socialCapital',
                            type: 'text',
                            required: true,
                            currency: true,
                            grid: {
                                xs: 12,
                                sm: 12,
                                md: 4,
                            },
                        },
                    ]
                }]

            } 
        ]
    }
}