import { InputForm } from "../types";

export namespace CreditCardFormBuild {
    export const form = (value: number, creditCardFees: number[]) => {
        return [
        {
            label: 'Número do Cartão *',
            id: 'cardNumber',
            required: true,
            type: 'text',
            mask: '9999 9999 9999 9999',
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        {
            label: 'Nome Impresso no Cartão *',
            id: 'holderName',
            required: true,
            type: 'text',
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 12,
            },
        },
        {
            label: 'Validade *',
            id: 'expirationDate',
            mask: '99/9999',
            required: true,
            type: 'text',
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        {
            label: 'CVV *',
            id: 'securityCode',
            type: 'text',
            mask: '999',
            required: true,
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 6,
            },
        },
        {
            label: 'Selecione a quantidade de parcelas',
            id: 'installmentQuantity',
            type: 'select',
            required: true,
            placeholder: '',
            grid: {
                xs: 12,
                sm: 12,
                md: 12,
            },
            options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((v, i) => {
                if (!value) return null
                const valueWithFees = value * (1 + (creditCardFees[i] / 100))

                return {
                    label: `${v}x de R$ ${(valueWithFees / v).toFixed(2)}`,
                    value: v,
                }
            })
        },
    ] as InputForm[];};
}