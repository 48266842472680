import { Typography, Box } from '@mui/material';

import FormBuilder from '../../form-builder';
import { CreateCnpjStepFormProps } from './create-cnpj-step-form.types';

export const CredentialsStepFour: React.FC<CreateCnpjStepFormProps> = ({
    label,
    sectionIndex,
    stepKey,
    inputs,
    errors,
    touched,
    values,
    setFieldValue,
    getFieldProps,
}) => {

    return (
        <Box style={{ margin: 15 }}>
            <div>
                <Typography color={'#2D2935'} marginBottom={1} marginTop={sectionIndex !== 0 ? 4 : 2} fontSize={24} fontWeight={600} >
                    Identifique-se no gov.br com:
                </Typography>
                <Typography marginBottom={4}>
                    O pagamento foi realizado com sucesso, em até 5 dias úteis entraremos em contato via E-mail.<br />Para agilizar o processo, informe o seu acesso ao site GOV.BR abaixo.
                </Typography>
            </div>
            <FormBuilder
                errors={errors}
                touched={touched}
                values={values}
                setFieldValue={setFieldValue}
                inputs={inputs}
                getFieldProps={getFieldProps}
            />
        </Box>
    )
}