import * as Yup from 'yup';
import { cpf } from 'cpf-cnpj-validator';

export const stepOneValidation = Yup.object().shape({
    fullName: Yup
        .string()
        .required('Informe o seu nome completo'),
    email: Yup
        .string()
        .email('Informe um email válido')
        .required('Informe o seu email'),
    cpf: Yup
        .string()
        .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, 'Formato inválido do CPF')
        .test('cpf', 'Informe o seu CPF correto', (value) => {
            if (!value) return false;
            return cpf.isValid(value.replace(/\D/g, '')) ? true : false
        })
        .required('Informe o seu CPF correto'),
    phone: Yup
        .string()
        .required('Informe o seu telefone'),
    rg: Yup
        .string()
        .required('Informe o seu rg'),
    rgEmissor: Yup
        .string()
        .required('Informe o órgão emissor do seu rg'),
    rgStateEmissor: Yup
        .string()
        .required('Selecione o estado emissor do seu rg'),
    mothername: Yup
        .string()
        .required('Informe o nome completo da sua mãe'),
    birthdate: Yup
        .string()
        .required('Informe a sua data de nascimento'),
});

export const stepTwoValidation = Yup.object().shape({
    // fantasyName: Yup
    //     .string()
    //     .required('Informe o nome fantasia da sua empresa'),
    socialCapital: Yup
        .string()
        .required('Informe o capital social da sua empresa'),
    principalOcupation: Yup
        .object().nullable()
        .required('Selecione a ocupação principal da sua empresa'),
    secondaryOcupations: Yup
        .array(Yup.object())
        .min(1, 'É necessário selecionar ao menos uma ocupação secundária')
        .max(15, 'O máximo de ocupações secundárias é 15, você selecionou uma quantia maior')
        .required('Selecione as ocupações secundárias da empresa'),
    actingType: Yup
        .object().nullable()
        .required('Selecione a forma de atuação da sua empresa')
});

export const stepTwoValidationInstallment = Yup.object().shape({
    installmentCnpj: Yup
    .string().min(14, 'O Cnpj deve conter no mínimo 14 caracteres')
    .max(20, 'O cnpj deve conter no máximo 20 caracteres')
    .required('Informe o cnpj da sua empresa'),
});


export const stepTwoValidationCnpj = Yup.object().shape({
    cnpj: Yup
        .string().min(14, 'O Cnpj deve conter no mínimo 14 caracteres')
        .max(20, 'O cnpj deve conter no máximo 20 caracteres')
        .required('Informe o cnpj da sua empresa'),
});

export const stepTwoValidationDeclaration = Yup.object().shape({
    cnpj: Yup
    .string().min(14, 'O Cnpj deve conter no mínimo 14 caracteres')
    .max(20, 'O cnpj deve conter no máximo 20 caracteres')
    .required('Informe o cnpj da sua empresa'),
    year: Yup.string().min(4, 'O ano não é válido').max(4, 'O ano não é válido'),
    annual_billing: Yup.string()
});

export const stepThreeOnlyPersonalValidation = Yup.object().shape({
    zipcode: Yup
        .string()
        .required('Informe o cep da sua cidade'),
    city: Yup
        .string()
        .required('Informe o nome da sua cidade'),
    district: Yup
        .string()
        .required('Informe o nome do seu bairro'),
    street: Yup
        .string()
        .required('Informe o nome da sua rua'),
    number: Yup
        .string()
        .required('Informe o número da sua casa/apartamento'),
    state: Yup
        .string()
        .required('Informe o seu estado'),
});