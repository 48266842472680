import { Container, Stack, Grid, Divider, Typography, Box, Stepper, Step, StepButton, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { useSelector } from 'react-redux';
import { When } from 'react-if';
import { FieldInputProps, FormikErrors, FormikTouched, useFormik } from 'formik';

import { Page } from '../../';
import HeaderBreadcrumbs from '../../../components/header-breadcrumbs';
import { RootState } from '../../../store';
import { UserFormStepBuilder } from '../../../builders/user-form-step.builder';
import FormBuilder from '../../form-builder';
import { InputForm } from '../../../types';
import { CreateCnpjStepFormProps } from './create-cnpj-step-form.types';


export const CreateCnpjStepOne: React.FC<CreateCnpjStepFormProps> = ({
    label,
    sectionIndex,
    stepKey,
    inputs,
    errors,
    touched,
    values,
    setFieldValue,
    getFieldProps,
}) => {

    return (
        <Box style={{ margin: 15 }}>
            <When condition={!['comercial_address', 'personal_address'].includes(stepKey)}>
                <Typography color={'#2D2935'} marginBottom={4} marginTop={sectionIndex !== 0 ? 4 : 2} fontSize={24} fontWeight={600} lineHeight={2} letterSpacing={0.2}>
                    {label}
                </Typography>
                <FormBuilder
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    inputs={inputs}
                    getFieldProps={getFieldProps}
                />
            </When>

            <When condition={stepKey === 'comercial_address' && true}>
                <Typography color={'#2D2935'} marginBottom={4} marginTop={sectionIndex !== 0 ? 4 : 2} fontSize={24} fontWeight={600} lineHeight={2} letterSpacing={0.2}>
                    {label}
                </Typography>
                <FormBuilder
                    errors={errors}
                    touched={touched}
                    values={values}
                    setFieldValue={setFieldValue}
                    inputs={inputs}
                    getFieldProps={getFieldProps}
                />
            </When>
        </Box>
    )
}