import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

interface Props {
    sx?: Object,
};

const Logo: React.FC<Props> = ({ sx }) => {
    const getLogoPath = (): string => {
        const paths: Record<string, string> = {
            'abrir.cadastromei.org': '/static/illustrations/logomei.webp',
            'abrir.meicontabil.com': '/static/illustrations/mei_contabil_marca_transparente.png',
            'abrir.portalmeumei.com': '/static/illustrations/logo-portalmeumei.png',
            'abrir.aberturamei.org': '/static/illustrations/aberturamei_logo.png',
            'abrir.empreendedorcnpj.com.br': '/static/illustrations/empreendedorcnpj_logo.png',
            'abrir.cnpjmei.com.br': '/static/illustrations/cnpjmei_logo.png',
        }

        return paths[window.location.hostname] || '/static/illustrations/logomei.webp';
    }

    const getDimensionsFromLocation = () => {
        const dimensions: any = {
            'abrir.cadastromei.org': {
                width: 250,
                height: 50
            },
            'abrir.meicontabil.com': {
                width: 250,
                height: 50
            },
            'abrir.portalmeumei.com': {
                width: 250,
                height: 50
            },
            'abrir.aberturamei.org': {
                width: 250,
                height: 50
            },
            'abrir.empreendedorcnpj.com.br': {
                width: 250,
                height: 50
            },
            'abrir.cnpjmei.com.br': {
                width: 250,
                height: 75
            },
        }

        return dimensions[window.location.hostname] || {
            width: 250,
            height: 50
        };
    }

    return (
        <Stack flexDirection="column" display="flex" alignContent={"center"} alignItems={"center"} justifyItems={"center"}>
            <img alt="logo" src={getLogoPath()} style={getDimensionsFromLocation()} />
        </Stack>
    )
}

export default Logo;
