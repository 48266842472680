import { ReactNode, useMemo } from 'react';

import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';

import shape from './shape';
import palette from './palette';
import typography from './typography';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';
import GlobalStyles from './globalStyles';

interface Props {
  children: ReactNode,
};

const ThemeConfig: React.FC<Props> = ({ children }) => {
  const isLight = true;

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light } : { ...palette.dark },
      shape,
      typography,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions as any);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles />
          {children}
        </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeConfig;