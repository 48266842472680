export const principalOcupationOptions = [
    {
      value: "Abatedor(a) De Aves Com Comercialização do Produto Independente",
      label: "Abatedor(a) De Aves Com Comercialização do Produto Independente"
    },
    {
      value: "Acabador(a) De Calçados",
      label: "Acabador(a) De Calçados"
    },
    {
      value: "Açougueiro(a)",
      label: "Açougueiro(a)"
    },
    {
      value: "Adestrador(a) De Animais Independente",
      label: "Adestrador(a) De Animais Independente"
    },
    {
      value: "Adestrador(a) De Cães De Guarda",
      label: "Adestrador(a) De Cães De Guarda"
    },
    {
      value: "Agente De Correio Franqueado E Permissionário",
      label: "Agente De Correio Franqueado E Permissionário"
    },
    {
      value: "Agente De Viagens",
      label: "Agente De Viagens"
    },
    {
      value: "Agente Funerário",
      label: "Agente Funerário"
    },
    {
      value: "Agente Matrimonial",
      label: "Agente Matrimonial"
    },
    {
      value: "Alfaiate",
      label: "Alfaiate"
    },
    {
      value: "Amolador(a) De Artigos De Cutelaria",
      label: "Amolador(a) De Artigos De Cutelaria"
    },
    {
      value: "Animador(a) De Festas",
      label: "Animador(a) De Festas"
    },
    {
      value: "Antiquário(a)",
      label: "Antiquário(a)"
    },
    {
      value: "Apicultor(a) Independente",
      label: "Apicultor(a) Independente"
    },
    {
      value: "Apurador(a), Coletor(a) E Fornecedor(a) De Recortes De Matérias Publicadas Em Jornais E Revistas",
      label: "Apurador(a), Coletor(a) E Fornecedor(a) De Recortes De Matérias Publicadas Em Jornais E Revistas"
    },
    {
      value: "Armador(a) De Ferragens Na Construção Civil",
      label: "Armador(a) De Ferragens Na Construção Civil"
    },
    {
      value: "Artesão(ã) Têxtil\t",
      label: "Artesão(ã) Têxtil"
    },
    {
      value: "Artesão(ã) De Bijuterias",
      label: "Artesão(ã) De Bijuterias"
    },
    {
      value: "Artesão(ã) Em Borracha",
      label: "Artesão(ã) Em Borracha"
    },
    {
      value: "Artesão(ã) Em Cerâmica",
      label: "Artesão(ã) Em Cerâmica"
    },
    {
      value: "Artesão(ã) Em Cimento",
      label: "Artesão(ã) Em Cimento"
    },
    {
      value: "Artesão(ã) Em Cortiça, Bambu E Afins",
      label: "Artesão(ã) Em Cortiça, Bambu E Afins"
    },
    {
      value: "Artesão(ã) Em Couro",
      label: "Artesão(ã) Em Couro"
    },
    {
      value: "Artesão(ã) Em Gesso",
      label: "Artesão(ã) Em Gesso"
    },
    {
      value: "Artesão(ã) Em Louças, Vidro E Cristal",
      label: "Artesão(ã) Em Louças, Vidro E Cristal"
    },
    {
      value: "Artesão(ã) Em Madeira",
      label: "Artesão(ã) Em Madeira"
    },
    {
      value: "Artesão(ã) Em Mármore, Granito, Ardósia E Outras Pedras",
      label: "Artesão(ã) Em Mármore, Granito, Ardósia E Outras Pedras"
    },
    {
      value: "Artesão(ã) Em Metais",
      label: "Artesão(ã) Em Metais"
    },
    {
      value: "Artesão(ã) Em Metais Preciosos",
      label: "Artesão(ã) Em Metais Preciosos"
    },
    {
      value: "Artesão(ã) Em Outros Materiais",
      label: "Artesão(ã) Em Outros Materiais"
    },
    {
      value: "Artesão(ã) Em Papel",
      label: "Artesão(ã) Em Papel"
    },
    {
      value: "Artesão(ã) Em Plástico",
      label: "Artesão(ã) Em Plástico"
    },
    {
      value: "Artesão(ã) Em Vidro",
      label: "Artesão(ã) Em Vidro"
    },
    {
      value: "Astrólogo(a)",
      label: "Astrólogo(a)"
    },
    {
      value: "Azulejista",
      label: "Azulejista"
    },
    {
      value: "Baleiro(a)",
      label: "Baleiro(a)"
    },
    {
      value: "Banhista De Animais Domésticos",
      label: "Banhista De Animais Domésticos"
    },
    {
      value: "Barbeiro Independente",
      label: "Barbeiro Independente"
    },
    {
      value: "Barqueiro(a)",
      label: "Barqueiro(a)"
    },
    {
      value: "Barraqueiro(a)",
      label: "Barraqueiro(a)"
    },
    {
      value: "Beneficiador(a) De Castanha",
      label: "Beneficiador(a) De Castanha"
    },
    {
      value: "Bike Propagandista",
      label: "Bike Propagandista"
    },
    {
      value: "Bikeboy (Ciclista Mensageiro)",
      label: "Bikeboy (Ciclista Mensageiro)"
    },
    {
      value: "Bolacheiro(a)",
      label: "Bolacheiro(a)"
    },
    {
      value: "Bombeiro(a) Hidráulico",
      label: "Bombeiro(a) Hidráulico"
    },
    {
      value: "Boneleiro(a) (Fabricante De Bonés)",
      label: "Boneleiro(a) (Fabricante De Bonés)"
    },
    {
      value: "Bordadeiro(a)",
      label: "Bordadeiro(a)"
    },
    {
      value: "Borracheiro(a)",
      label: "Borracheiro(a)"
    },
    {
      value: "Britador",
      label: "Britador"
    },
    {
      value: "Cabeleireiro(a)",
      label: "Cabeleireiro(a)"
    },
    {
      value: "Calafetador(a)",
      label: "Calafetador(a)"
    },
    {
      value: "Calheiro(a)",
      label: "Calheiro(a)"
    },
    {
      value: "Caminhoneiro (a) De Cargas Não Perigosas, Intermunicipal E Interestadual",
      label: "Caminhoneiro (a) De Cargas Não Perigosas, Intermunicipal E Interestadual"
    },
    {
      value: "Cantor(a)/músico(a) Independente",
      label: "Cantor(a)/músico(a) Independente"
    },
    {
      value: "Capoteiro(a)",
      label: "Capoteiro(a)"
    },
    {
      value: "Carpinteiro(a)",
      label: "Carpinteiro(a)"
    },
    {
      value: "Carpinteiro(a) Instalador(a)",
      label: "Carpinteiro(a) Instalador(a)"
    },
    {
      value: "Carregador (Veículos De Transportes Terrestres)",
      label: "Carregador (Veículos De Transportes Terrestres)"
    },
    {
      value: "Carregador De Malas",
      label: "Carregador De Malas"
    },
    {
      value: "Carroceiro – Coleta De Entulhos E Resíduos",
      label: "Carroceiro – Coleta De Entulhos E Resíduos"
    },
    {
      value: "Carroceiro – Transporte De Carga",
      label: "Carroceiro – Transporte De Carga"
    },
    {
      value: "Carroceiro – Transporte De Mudança",
      label: "Carroceiro – Transporte De Mudança"
    },
    {
      value: "Cartazista, Pintor De Faixas Publicitárias E De Letras",
      label: "Cartazista, Pintor De Faixas Publicitárias E De Letras"
    },
    {
      value: "Chapeleiro(a)",
      label: "Chapeleiro(a)"
    },
    {
      value: "Chaveiro(a)",
      label: "Chaveiro(a)"
    },
    {
      value: "Chocolateiro(a)",
      label: "Chocolateiro(a)"
    },
    {
      value: "Churrasqueiro(a) Ambulante",
      label: "Churrasqueiro(a) Ambulante"
    },
    {
      value: "Churrasqueiro(a) Em Domicílio",
      label: "Churrasqueiro(a) Em Domicílio"
    },
    {
      value: "Clicherista",
      label: "Clicherista"
    },
    {
      value: "Cobrador(a) De Dívidas",
      label: "Cobrador(a) De Dívidas"
    },
    {
      value: "Colchoeiro(a)",
      label: "Colchoeiro(a)"
    },
    {
      value: "Coletor De Resíduos Não-perigosos",
      label: "Coletor De Resíduos Não-perigosos"
    },
    {
      value: "Colocador(a) De Piercing",
      label: "Colocador(a) De Piercing"
    },
    {
      value: "Colocador(a) De Revestimentos",
      label: "Colocador(a) De Revestimentos"
    },
    {
      value: "Comerciante De Artigos E Alimentos Para Animais De Estimação (Pet Shop) Independente (não inclui a venda de medicamentos)",
      label: "Comerciante De Artigos E Alimentos Para Animais De Estimação (Pet Shop) Independente (não inclui a venda de medicamentos)"
    },
    {
      value: "Comerciante De Artigos De Armarinho",
      label: "Comerciante De Artigos De Armarinho"
    },
    {
      value: "Comerciante De Artigos De Bebê",
      label: "Comerciante De Artigos De Bebê"
    },
    {
      value: "Comerciante De Artigos De Caça, Pesca E Camping",
      label: "Comerciante De Artigos De Caça, Pesca E Camping"
    },
    {
      value: "Comerciante De Artigos De Cama, Mesa E Banho",
      label: "Comerciante De Artigos De Cama, Mesa E Banho"
    },
    {
      value: "Comerciante De Artigos De Colchoaria",
      label: "Comerciante De Artigos De Colchoaria"
    },
    {
      value: "Comerciante De Artigos De Cutelaria",
      label: "Comerciante De Artigos De Cutelaria"
    },
    {
      value: "Comerciante De Artigos De Iluminação",
      label: "Comerciante De Artigos De Iluminação"
    },
    {
      value: "Comerciante De Artigos De Joalheria",
      label: "Comerciante De Artigos De Joalheria"
    },
    {
      value: "Comerciante De Artigos De Óptica",
      label: "Comerciante De Artigos De Óptica"
    },
    {
      value: "Comerciante De Artigos De Relojoaria",
      label: "Comerciante De Artigos De Relojoaria"
    },
    {
      value: "Comerciante De Artigos De Tapeçaria, Cortinas E Persianas",
      label: "Comerciante De Artigos De Tapeçaria, Cortinas E Persianas"
    },
    {
      value: "Comerciante De Artigos De Viagem",
      label: "Comerciante De Artigos De Viagem"
    },
    {
      value: "Comerciante De Artigos Do Vestuário E Acessórios",
      label: "Comerciante De Artigos Do Vestuário E Acessórios"
    },
    {
      value: "Comerciante De Artigos Eróticos",
      label: "Comerciante De Artigos Eróticos"
    },
    {
      value: "Comerciante De Artigos Esportivos",
      label: "Comerciante De Artigos Esportivos"
    },
    {
      value: "Comerciante De Artigos Fotográficos E Para Filmagem",
      label: "Comerciante De Artigos Fotográficos E Para Filmagem"
    },
    {
      value: "Comerciante De Artigos Funerários",
      label: "Comerciante De Artigos Funerários"
    },
    {
      value: "Comerciante De Artigos Médicos E Ortopédicos",
      label: "Comerciante De Artigos Médicos E Ortopédicos"
    },
    {
      value: "Comerciante De Artigos Para Habitação",
      label: "Comerciante De Artigos Para Habitação"
    },
    {
      value: "Comerciante De Artigos Usados",
      label: "Comerciante De Artigos Usados"
    },
    {
      value: "Comerciante De Bebidas",
      label: "Comerciante De Bebidas"
    },
    {
      value: "Comerciante De Bicicletas E Triciclos; Peças E Acessórios",
      label: "Comerciante De Bicicletas E Triciclos; Peças E Acessórios"
    },
    {
      value: "Comerciante De Brinquedos E Artigos Recreativos",
      label: "Comerciante De Brinquedos E Artigos Recreativos"
    },
    {
      value: "Comerciante De Cal, Areia, Pedra Britada, Tijolos E Telhas",
      label: "Comerciante De Cal, Areia, Pedra Britada, Tijolos E Telhas"
    },
    {
      value: "Comerciante De Calçados",
      label: "Comerciante De Calçados"
    },
    {
      value: "Comerciante De Carvão E Lenha",
      label: "Comerciante De Carvão E Lenha"
    },
    {
      value: "Comerciante De Cestas De Café Da Manhã",
      label: "Comerciante De Cestas De Café Da Manhã"
    },
    {
      value: "Comerciante De Cosméticos E Artigos De Perfumaria",
      label: "Comerciante De Cosméticos E Artigos De Perfumaria"
    },
    {
      value: "Comerciante De Discos, Cds, Dvds E Fitas",
      label: "Comerciante De Discos, Cds, Dvds E Fitas"
    },
    {
      value: "Comerciante De Eletrodomésticos E Equipamentos De Áudio E Vídeo",
      label: "Comerciante De Eletrodomésticos E Equipamentos De Áudio E Vídeo"
    },
    {
      value: "Comerciante De Embalagens",
      label: "Comerciante De Embalagens"
    },
    {
      value: "Comerciante De Equipamentos De Telefonia E Comunicação",
      label: "Comerciante De Equipamentos De Telefonia E Comunicação"
    },
    {
      value: "Comerciante De Equipamentos E Suprimentos De Informática",
      label: "Comerciante De Equipamentos E Suprimentos De Informática"
    },
    {
      value: "Comerciante De Equipamentos Para Escritório",
      label: "Comerciante De Equipamentos Para Escritório"
    },
    {
      value: "Comerciante De Ferragens E Ferramentas",
      label: "Comerciante De Ferragens E Ferramentas"
    },
    {
      value: "Comerciante De Flores, Plantas E Frutas Artificiais",
      label: "Comerciante De Flores, Plantas E Frutas Artificiais"
    },
    {
      value: "Comerciante De Inseticidas E Raticidas",
      label: "Comerciante De Inseticidas E Raticidas"
    },
    {
      value: "Comerciante De Instrumentos Musicais E Acessórios",
      label: "Comerciante De Instrumentos Musicais E Acessórios"
    },
    {
      value: "Comerciante De Laticínios",
      label: "Comerciante De Laticínios"
    },
    {
      value: "Comerciante De Lubrificantes",
      label: "Comerciante De Lubrificantes"
    },
    {
      value: "Comerciante De Madeira E Artefatos",
      label: "Comerciante De Madeira E Artefatos"
    },
    {
      value: "Comerciante De Materiais De Construção Em Geral",
      label: "Comerciante De Materiais De Construção Em Geral"
    },
    {
      value: "Comerciante De Materiais Hidráulicos",
      label: "Comerciante De Materiais Hidráulicos"
    },
    {
      value: "Comerciante De Material Elétrico",
      label: "Comerciante De Material Elétrico"
    },
    {
      value: "Comerciante De Miudezas E Quinquilharias\t",
      label: "Comerciante De Miudezas E Quinquilharias"
    },
    {
      value: "Comerciante De Molduras E Quadros",
      label: "Comerciante De Molduras E Quadros"
    },
    {
      value: "Comerciante De Móveis",
      label: "Comerciante De Móveis"
    },
    {
      value: "Comerciante De Objetos De Arte",
      label: "Comerciante De Objetos De Arte"
    },
    {
      value: "Comerciante De Peças E Acessórios Novos Para Veículos Automotores",
      label: "Comerciante De Peças E Acessórios Novos Para Veículos Automotores"
    },
    {
      value: "Comerciante De Peças E Acessórios Para Aparelhos Eletroeletrônicos Para Uso Doméstico",
      label: "Comerciante De Peças E Acessórios Para Aparelhos Eletroeletrônicos Para Uso Doméstico"
    },
    {
      value: "Comerciante De Peças E Acessórios Novos Para Motocicletas E Motonetas",
      label: "Comerciante De Peças E Acessórios Novos Para Motocicletas E Motonetas"
    },
    {
      value: "Comerciante De Peças E Acessórios Usados Para Motocicletas E Motonetas",
      label: "Comerciante De Peças E Acessórios Usados Para Motocicletas E Motonetas"
    },
    {
      value: "Comerciante De Peças E Acessórios Usados Para Veículos Automotores",
      label: "Comerciante De Peças E Acessórios Usados Para Veículos Automotores"
    },
    {
      value: "Comerciante De Perucas",
      label: "Comerciante De Perucas"
    },
    {
      value: "Comerciante De Plantas, Flores Naturais, Vasos E Adubos\t",
      label: "Comerciante De Plantas, Flores Naturais, Vasos E Adubos"
    },
    {
      value: "Comerciante De Pneumáticos E Câmaras-de-ar",
      label: "Comerciante De Pneumáticos E Câmaras-de-ar"
    },
    {
      value: "Comerciante De Produtos De Higiene Pessoal",
      label: "Comerciante De Produtos De Higiene Pessoal"
    },
    {
      value: "Comerciante De Produtos De Limpeza",
      label: "Comerciante De Produtos De Limpeza"
    },
    {
      value: "Comerciante De Produtos De Panificação",
      label: "Comerciante De Produtos De Panificação"
    },
    {
      value: "Comerciante De Produtos De Tabacaria",
      label: "Comerciante De Produtos De Tabacaria"
    },
    {
      value: "Comerciante De Produtos Naturais",
      label: "Comerciante De Produtos Naturais"
    },
    {
      value: "Comerciante De Produtos Para Festas E Natal",
      label: "Comerciante De Produtos Para Festas E Natal"
    },
    {
      value: "Comerciante De Produtos Para Piscinas",
      label: "Comerciante De Produtos Para Piscinas"
    },
    {
      value: "Comerciante De Produtos Religiosos",
      label: "Comerciante De Produtos Religiosos"
    },
    {
      value: "Comerciante De Redes Para Dormir",
      label: "Comerciante De Redes Para Dormir"
    },
    {
      value: "Comerciante De Sistema De Segurança Residencial",
      label: "Comerciante De Sistema De Segurança Residencial"
    },
    {
      value: "Comerciante De Suvenires, Bijuterias E Artesanatos",
      label: "Comerciante De Suvenires, Bijuterias E Artesanatos"
    },
    {
      value: "Comerciante De Tecidos",
      label: "Comerciante De Tecidos"
    },
    {
      value: "Comerciante De Tintas E Materiais Para Pintura",
      label: "Comerciante De Tintas E Materiais Para Pintura"
    },
    {
      value: "Comerciante De Toldos E Papel De Parede",
      label: "Comerciante De Toldos E Papel De Parede"
    },
    {
      value: "Comerciante De Vidros",
      label: "Comerciante De Vidros"
    },
    {
      value: "Compoteiro(A)",
      label: "Compoteiro(A)"
    },
    {
      value: "Confeccionador(A) De Carimbos",
      label: "Confeccionador(A) De Carimbos"
    },
    {
      value: "Confeiteiro(A)",
      label: "Confeiteiro(A)"
    },
    {
      value: "Costureiro(A) De Roupas, Exceto Sob Medida",
      label: "Costureiro(A) De Roupas, Exceto Sob Medida"
    },
    {
      value: "Costureiro(A) De Roupas, Sob Medida",
      label: "Costureiro(A) De Roupas, Sob Medida"
    },
    {
      value: "Cozinheiro(A) Que Fornece Refeições Prontas E Embaladas Para Consumo\t",
      label: "Cozinheiro(A) Que Fornece Refeições Prontas E Embaladas Para Consumo"
    },
    {
      value: "Criador(A) De Animais Domésticos",
      label: "Criador(A) De Animais Domésticos"
    },
    {
      value: "Criador(A) De Peixes Ornamentais Em Água Doce",
      label: "Criador(A) De Peixes Ornamentais Em Água Doce"
    },
    {
      value: "Criador(A) De Peixes Ornamentais Em Água Salgada",
      label: "Criador(A) De Peixes Ornamentais Em Água Salgada"
    },
    {
      value: "Crocheteiro(A)",
      label: "Crocheteiro(A)"
    },
    {
      value: "Cuidador(A) De Animais (Pet Sitter)",
      label: "Cuidador(A) De Animais (Pet Sitter)"
    },
    {
      value: "Cunhador(A) De Moedas E Medalhas",
      label: "Cunhador(A) De Moedas E Medalhas"
    },
    {
      value: "Curtidor De Couro",
      label: "Curtidor De Couro"
    },
    {
      value: "Customizador(a) De Roupas",
      label: "Customizador(a) De Roupas"
    },
    {
      value: "Depilador(a)",
      label: "Depilador(a)"
    },
    {
      value: "Diarista",
      label: "Diarista"
    },
    {
      value: "Digitador(a)",
      label: "Digitador(a)"
    },
    {
      value: "Disc Jockey (Dj) Ou Video Jockey (Vj)",
      label: "Disc Jockey (Dj) Ou Video Jockey (Vj)"
    },
    {
      value: "Distribuidor(A) De Água Potável Em Caminhão Pipa",
      label: "Distribuidor(A) De Água Potável Em Caminhão Pipa"
    },
    {
      value: "Doceiro(A)",
      label: "Doceiro(A)"
    },
    {
      value: "Dublador(A)",
      label: "Dublador(A)"
    },
    {
      value: "Editor(A) De Jornais Diários",
      label: "Editor(A) De Jornais Diários"
    },
    {
      value: "Editor(A) De Jornais Não Diários",
      label: "Editor(A) De Jornais Não Diários"
    },
    {
      value: "Editor(A) De Lista De Dados E De Outras Informações",
      label: "Editor(A) De Lista De Dados E De Outras Informações"
    },
    {
      value: "Editor(A) De Livros",
      label: "Editor(A) De Livros"
    },
    {
      value: "Editor(A) De Revistas",
      label: "Editor(A) De Revistas"
    },
    {
      value: "Editor(A) De Vídeo",
      label: "Editor(A) De Vídeo"
    },
    {
      value: "Eletricista De Automóveis",
      label: "Eletricista De Automóveis"
    },
    {
      value: "Eletricista Em Residências E Estabelecimentos Comerciais",
      label: "Eletricista Em Residências E Estabelecimentos Comerciais"
    },
    {
      value: "Encadernador(A)",
      label: "Encadernador(A)"
    },
    {
      value: "Encanador",
      label: "Encanador"
    },
    {
      value: "Engraxate",
      label: "Engraxate"
    },
    {
      value: "Entregador De Malotes",
      label: "Entregador De Malotes"
    },
    {
      value: "Envasador(A) E Empacotador(A)",
      label: "Envasador(A) E Empacotador(A)"
    },
    {
      value: "Estampador(A) De Peças Do Vestuário",
      label: "Estampador(A) De Peças Do Vestuário"
    },
    {
      value: "Esteticista",
      label: "Esteticista"
    },
    {
      value: "Esteticista De Animais Domésticos",
      label: "Esteticista De Animais Domésticos"
    },
    {
      value: "Estofador(A)",
      label: "Estofador(A)"
    },
    {
      value: "Fabricante De Açúcar Mascavo",
      label: "Fabricante De Açúcar Mascavo"
    },
    {
      value: "Fabricante De Alimentos Prontos Congelados",
      label: "Fabricante De Alimentos Prontos Congelados"
    },
    {
      value: "Fabricante De Amendoim E Castanha De Caju Torrados E Salgados",
      label: "Fabricante De Amendoim E Castanha De Caju Torrados E Salgados"
    },
    {
      value: "Fabricante De Amido E Féculas De Vegetais",
      label: "Fabricante De Amido E Féculas De Vegetais"
    },
    {
      value: "Fabricante De Artefatos De Funilaria",
      label: "Fabricante De Artefatos De Funilaria"
    },
    {
      value: "Fabricante De Artefatos Estampados De Metal, Sob Encomenda Ou Não",
      label: "Fabricante De Artefatos Estampados De Metal, Sob Encomenda Ou Não"
    },
    {
      value: "Fabricante De Artefatos Para Pesca E Esporte",
      label: "Fabricante De Artefatos Para Pesca E Esporte"
    },
    {
      value: "Fabricante De Artefatos Têxteis Para Uso Doméstico",
      label: "Fabricante De Artefatos Têxteis Para Uso Doméstico"
    },
    {
      value: "Fabricante De Artigos De Cutelaria",
      label: "Fabricante De Artigos De Cutelaria"
    },
    {
      value: "Fabricante De Aviamentos Para Costura",
      label: "Fabricante De Aviamentos Para Costura"
    },
    {
      value: "Fabricante De Balas, Confeitos E Frutas Cristalizadas",
      label: "Fabricante De Balas, Confeitos E Frutas Cristalizadas"
    },
    {
      value: "Fabricante De Bolsas/bolseiro",
      label: "Fabricante De Bolsas/bolseiro"
    },
    {
      value: "Fabricante De Brinquedos Não Eletrônicos",
      label: "Fabricante De Brinquedos Não Eletrônicos"
    },
    {
      value: "Fabricante De Calçados De Borracha, Madeira E Tecidos E Fibras",
      label: "Fabricante De Calçados De Borracha, Madeira E Tecidos E Fibras"
    },
    {
      value: "Fabricante De Calçados De Couro",
      label: "Fabricante De Calçados De Couro"
    },
    {
      value: "Fabricante De Chá",
      label: "Fabricante De Chá"
    },
    {
      value: "Fabricante De Cintos/cinteiro",
      label: "Fabricante De Cintos/cinteiro"
    },
    {
      value: "Fabricante De Conservas De Frutas",
      label: "Fabricante De Conservas De Frutas"
    },
    {
      value: "Fabricante De Conservas De Legumes E Outros Vegetais",
      label: "Fabricante De Conservas De Legumes E Outros Vegetais"
    },
    {
      value: "Fabricante De Embalagens De Cartolina E Papel-cartão",
      label: "Fabricante De Embalagens De Cartolina E Papel-cartão"
    },
    {
      value: "Fabricante De Embalagens De Madeira",
      label: "Fabricante De Embalagens De Madeira"
    },
    {
      value: "Fabricante De Embalagens De Papel",
      label: "Fabricante De Embalagens De Papel"
    },
    {
      value: "Fabricante De Especiarias",
      label: "Fabricante De Especiarias"
    },
    {
      value: "Fabricante De Esquadrias Metálicas Sob Encomenda Ou Não",
      label: "Fabricante De Esquadrias Metálicas Sob Encomenda Ou Não"
    },
    {
      value: "Fabricante De Fios De Algodão",
      label: "Fabricante De Fios De Algodão"
    },
    {
      value: "Fabricante De Fios De Linho, Rami, Juta, Seda E Lã",
      label: "Fabricante De Fios De Linho, Rami, Juta, Seda E Lã"
    },
    {
      value: "Fabricante De Fumo E Derivados Do Fumo",
      label: "Fabricante De Fumo E Derivados Do Fumo"
    },
    {
      value: "Fabricante De Geléia De Mocotó",
      label: "Fabricante De Geléia De Mocotó"
    },
    {
      value: "Fabricante De Gelo Comum",
      label: "Fabricante De Gelo Comum"
    },
    {
      value: "Fabricante De Guarda-chuvas E Similares",
      label: "Fabricante De Guarda-chuvas E Similares"
    },
    {
      value: "Fabricante De Guardanapos E Copos De Papel",
      label: "Fabricante De Guardanapos E Copos De Papel"
    },
    {
      value: "Fabricante De Instrumentos Musicais",
      label: "Fabricante De Instrumentos Musicais"
    },
    {
      value: "Fabricante De Jogos Recreativos",
      label: "Fabricante De Jogos Recreativos"
    },
    {
      value: "Fabricante De Laticínios",
      label: "Fabricante De Laticínios"
    },
    {
      value: "Fabricante De Letreiros, Placas E Painéis Não Luminosos, Sob Encomenda Ou Não",
      label: "Fabricante De Letreiros, Placas E Painéis Não Luminosos, Sob Encomenda Ou Não"
    },
    {
      value: "Fabricante De Luminárias E Outros Equipamentos De Iluminação",
      label: "Fabricante De Luminárias E Outros Equipamentos De Iluminação"
    },
    {
      value: "Fabricante De Malas",
      label: "Fabricante De Malas"
    },
    {
      value: "Fabricante De Massas Alimentícias",
      label: "Fabricante De Massas Alimentícias"
    },
    {
      value: "Fabricante De Meias",
      label: "Fabricante De Meias"
    },
    {
      value: "Fabricante De Mochilas E Carteiras",
      label: "Fabricante De Mochilas E Carteiras"
    },
    {
      value: "Fabricante De Painéis E Letreiros Luminosos",
      label: "Fabricante De Painéis E Letreiros Luminosos"
    },
    {
      value: "Fabricante De Pão De Queijo Congelado",
      label: "Fabricante De Pão De Queijo Congelado"
    },
    {
      value: "Fabricante De Papel",
      label: "Fabricante De Papel"
    },
    {
      value: "Fabricante De Partes De Peças Do Vestuário – Facção",
      label: "Fabricante De Partes De Peças Do Vestuário – Facção"
    },
    {
      value: "Fabricante De Partes De Roupas Íntimas – Facção",
      label: "Fabricante De Partes De Roupas Íntimas – Facção"
    },
    {
      value: "Fabricante De Partes De Roupas Profissionais – Facção",
      label: "Fabricante De Partes De Roupas Profissionais – Facção"
    },
    {
      value: "Fabricante De Partes Para Calçados",
      label: "Fabricante De Partes Para Calçados"
    },
    {
      value: "Fabricante De Polpas De Frutas",
      label: "Fabricante De Polpas De Frutas"
    },
    {
      value: "Fabricante De Produtos De Soja",
      label: "Fabricante De Produtos De Soja"
    },
    {
      value: "Fabricante De Produtos De Tecido Não Tecido Para Uso Odonto-médico- Hospitalar",
      label: "Fabricante De Produtos De Tecido Não Tecido Para Uso Odonto-médico- Hospitalar"
    },
    {
      value: "Fabricante De Produtos Derivados De Carne",
      label: "Fabricante De Produtos Derivados De Carne"
    },
    {
      value: "Fabricante De Produtos Derivados Do Arroz",
      label: "Fabricante De Produtos Derivados Do Arroz"
    },
    {
      value: "Fabricante De Rapadura E Melaço",
      label: "Fabricante De Rapadura E Melaço"
    },
    {
      value: "Fabricante De Refrescos, Xaropes E Pós Para Refrescos",
      label: "Fabricante De Refrescos, Xaropes E Pós Para Refrescos"
    },
    {
      value: "Fabricante De Roupas Íntimas",
      label: "Fabricante De Roupas Íntimas"
    },
    {
      value: "Fabricante De Sucos Concentrados De Frutas, Hortaliças E Legumes",
      label: "Fabricante De Sucos Concentrados De Frutas, Hortaliças E Legumes"
    },
    {
      value: "Fabricante De Sucos De Frutas, Hortaliças E Legumes",
      label: "Fabricante De Sucos De Frutas, Hortaliças E Legumes"
    },
    {
      value: "Fabricante De Velas, Inclusive Decorativas",
      label: "Fabricante De Velas, Inclusive Decorativas"
    },
    {
      value: "Farinheiro De Mandioca",
      label: "Farinheiro De Mandioca"
    },
    {
      value: "Farinheiro De Milho",
      label: "Farinheiro De Milho"
    },
    {
      value: "Ferramenteiro(A)",
      label: "Ferramenteiro(A)"
    },
    {
      value: "Ferreiro/forjador",
      label: "Ferreiro/forjador"
    },
    {
      value: "Filmador(A)",
      label: "Filmador(A)"
    },
    {
      value: "Fornecedor(A) De Alimentos Preparados Para Empresas",
      label: "Fornecedor(A) De Alimentos Preparados Para Empresas"
    },
    {
      value: "Fosseiro (Limpador De Fossa)",
      label: "Fosseiro (Limpador De Fossa)"
    },
    {
      value: "Fotocopiador(A)",
      label: "Fotocopiador(A)"
    },
    {
      value: "Fotógrafo(A)",
      label: "Fotógrafo(A)"
    },
    {
      value: "Fotógrafo(A) Aéreo",
      label: "Fotógrafo(A) Aéreo"
    },
    {
      value: "Fotógrafo(A) Submarino",
      label: "Fotógrafo(A) Submarino"
    },
    {
      value: "Funileiro / Lanterneiro",
      label: "Funileiro / Lanterneiro"
    },
    {
      value: "Galvanizador(A)",
      label: "Galvanizador(A)"
    },
    {
      value: "Gesseiro(A)",
      label: "Gesseiro(A)"
    },
    {
      value: "Gravador(A) De Carimbos",
      label: "Gravador(A) De Carimbos"
    },
    {
      value: "Guardador(A) De Móveis",
      label: "Guardador(A) De Móveis"
    },
    {
      value: "Guia De Turismo",
      label: "Guia De Turismo"
    },
    {
      value: "Guincheiro (Reboque De Veículos)",
      label: "Guincheiro (Reboque De Veículos)"
    },
    {
      value: "Humorista E Contador De Histórias",
      label: "Humorista E Contador De Histórias"
    },
    {
      value: "Instalador(A) De Antenas De Tv",
      label: "Instalador(A) De Antenas De Tv"
    },
    {
      value: "Instalador(A) De Equipamentos De Segurança Domiciliar E Empresarial, Sem Prestação De Serviços De Vigilância E Segurança",
      label: "Instalador(A) De Equipamentos De Segurança Domiciliar E Empresarial, Sem Prestação De Serviços De Vigilância E Segurança"
    },
    {
      value: "Instalador(A) De Equipamentos Para Orientação À Navegação Marítima, Fluvial E Lacustre",
      label: "Instalador(A) De Equipamentos Para Orientação À Navegação Marítima, Fluvial E Lacustre"
    },
    {
      value: "Instalador(A) De Isolantes Acústicos E De Vibração",
      label: "Instalador(A) De Isolantes Acústicos E De Vibração"
    },
    {
      value: "Instalador(A) De Isolantes Térmicos",
      label: "Instalador(A) De Isolantes Térmicos"
    },
    {
      value: "Instalador(A) De Máquinas E Equipamentos Industriais",
      label: "Instalador(A) De Máquinas E Equipamentos Industriais"
    },
    {
      value: "Instalador(A) De Painéis Publicitários",
      label: "Instalador(A) De Painéis Publicitários"
    },
    {
      value: "Instalador(A) De Rede De Computadores",
      label: "Instalador(A) De Rede De Computadores"
    },
    {
      value: "Instalador(A) De Sistema De Prevenção Contra Incêndio",
      label: "Instalador(A) De Sistema De Prevenção Contra Incêndio"
    },
    {
      value: "Instalador(A) E Reparador (A) De Acessórios Automotivos",
      label: "Instalador(A) E Reparador (A) De Acessórios Automotivos"
    },
    {
      value: "Instalador(A) E Reparador De Cofres, Trancas E Travas De Segurança",
      label: "Instalador(A) E Reparador De Cofres, Trancas E Travas De Segurança"
    },
    {
      value: "Instalador(A) E Reparador(A) De Elevadores, Escadas E Esteiras Rolantes",
      label: "Instalador(A) E Reparador(A) De Elevadores, Escadas E Esteiras Rolantes"
    },
    {
      value: "Instalador(A) E Reparador(A) De Sistemas Centrais De Ar Condicionado, De Ventilação E Refrigeração",
      label: "Instalador(A) E Reparador(A) De Sistemas Centrais De Ar Condicionado, De Ventilação E Refrigeração"
    },
    {
      value: "Instrutor(A) De Arte E Cultura Em Geral",
      label: "Instrutor(A) De Arte E Cultura Em Geral"
    },
    {
      value: "Instrutor(A) De Artes Cênicas",
      label: "Instrutor(A) De Artes Cênicas"
    },
    {
      value: "Instrutor(A) De Cursos Gerenciais",
      label: "Instrutor(A) De Cursos Gerenciais"
    },
    {
      value: "Instrutor(A) De Cursos Preparatórios",
      label: "Instrutor(A) De Cursos Preparatórios"
    },
    {
      value: "Instrutor(A) De Idiomas",
      label: "Instrutor(A) De Idiomas"
    },
    {
      value: "Instrutor(A) De Informática",
      label: "Instrutor(A) De Informática"
    },
    {
      value: "Instrutor(A) De Música",
      label: "Instrutor(A) De Música"
    },
    {
      value: "Jardineiro(A)",
      label: "Jardineiro(A)"
    },
    {
      value: "Jornaleiro(A)",
      label: "Jornaleiro(A)"
    },
    {
      value: "Lapidador(A)",
      label: "Lapidador(A)"
    },
    {
      value: "Lavadeiro(A) De Roupas",
      label: "Lavadeiro(A) De Roupas"
    },
    {
      value: "Lavadeiro(A) De Roupas Profissionais",
      label: "Lavadeiro(A) De Roupas Profissionais"
    },
    {
      value: "Lavador(A) De Estofado E Sofá",
      label: "Lavador(A) De Estofado E Sofá"
    },
    {
      value: "Lavador(A) E Polidor De Carro",
      label: "Lavador(A) E Polidor De Carro"
    },
    {
      value: "Livreiro(A)",
      label: "Livreiro(A)"
    },
    {
      value: "Locador De Andaimes",
      label: "Locador De Andaimes"
    },
    {
      value: "Locador(A) De Aparelhos De Jogos Eletrônicos",
      label: "Locador(A) De Aparelhos De Jogos Eletrônicos"
    },
    {
      value: "LOCADOR(A) DE BICICLETAS, INDEPENDENTE",
      label: "LOCADOR(A) DE BICICLETAS, INDEPENDENTE"
    },
    {
      value: "Locador(A) De Equipamentos Científicos, Médicos E Hospitalares, Sem Operador",
      label: "Locador(A) De Equipamentos Científicos, Médicos E Hospitalares, Sem Operador"
    },
    {
      value: "Locador(A) De Equipamentos Recreativos E Esportivos",
      label: "Locador(A) De Equipamentos Recreativos E Esportivos"
    },
    {
      value: "Locador(A) De Fitas De Vídeo, Dvds E Similares",
      label: "Locador(A) De Fitas De Vídeo, Dvds E Similares"
    },
    {
      value: "Locador(A) De Instrumentos Musicais",
      label: "Locador(A) De Instrumentos Musicais"
    },
    {
      value: "Locador(A) De Livros, Revistas, Plantas E Flores",
      label: "Locador(A) De Livros, Revistas, Plantas E Flores"
    },
    {
      value: "Locador(A) De Máquinas E Equipamentos Agrícolas Sem Operador",
      label: "Locador(A) De Máquinas E Equipamentos Agrícolas Sem Operador"
    },
    {
      value: "Locador(A) De Máquinas E Equipamentos Para Construção Sem Operador, Exceto Andaimes",
      label: "Locador(A) De Máquinas E Equipamentos Para Construção Sem Operador, Exceto Andaimes"
    },
    {
      value: "Locador(A) De Máquinas E Equipamentos Para Escritório",
      label: "Locador(A) De Máquinas E Equipamentos Para Escritório"
    },
    {
      value: "LOCADOR(A) DE MATERIAL E EQUIPAMENTO ESPORTIVO, INDEPENDENTE",
      label: "LOCADOR(A) DE MATERIAL E EQUIPAMENTO ESPORTIVO, INDEPENDENTE"
    },
    {
      value: "Locador(A) De Material Médico",
      label: "Locador(A) De Material Médico"
    },
    {
      value: "LOCADOR(A) DE MOTOCICLETA, SEM CONDUTOR, INDEPENDENTE",
      label: "LOCADOR(A) DE MOTOCICLETA, SEM CONDUTOR, INDEPENDENTE"
    },
    {
      value: "Locador(A) De Móveis E Utensílios, Inclusive Para Festas",
      label: "Locador(A) De Móveis E Utensílios, Inclusive Para Festas"
    },
    {
      value: "Locador(a) De Objetos Do Vestuário, Jóias E Acessórios",
      label: "Locador(a) De Objetos Do Vestuário, Jóias E Acessórios"
    },
    {
      value: "Locador(a) De Outras Máquinas E Equipamentos Comerciais E Industriais Não Especificados Anteriormente, Sem Operador",
      label: "Locador(a) De Outras Máquinas E Equipamentos Comerciais E Industriais Não Especificados Anteriormente, Sem Operador"
    },
    {
      value: "Locador(a) De Palcos, Coberturas E Outras Estruturas De Uso Temporário, Exceto Andaimes",
      label: "Locador(a) De Palcos, Coberturas E Outras Estruturas De Uso Temporário, Exceto Andaimes"
    },
    {
      value: "LOCADOR(A) DE VÍDEO GAMES, INDEPENDENTE\t",
      label: "LOCADOR(A) DE VÍDEO GAMES, INDEPENDENTE"
    },
    {
      value: "Locutor(a) De Mensagens Fonadas E Ao Vivo",
      label: "Locutor(a) De Mensagens Fonadas E Ao Vivo"
    },
    {
      value: "Mágico(a)",
      label: "Mágico(a)"
    },
    {
      value: "Manicure/pedicure",
      label: "Manicure/pedicure"
    },
    {
      value: "Maquiador(a)",
      label: "Maquiador(a)"
    },
    {
      value: "Marceneiro (a) Sob Encomenda Ou Não",
      label: "Marceneiro (a) Sob Encomenda Ou Não"
    },
    {
      value: "Marmiteiro(a)",
      label: "Marmiteiro(a)"
    },
    {
      value: "Mecânico(a) De Motocicletas E Motonetas",
      label: "Mecânico(a) De Motocicletas E Motonetas"
    },
    {
      value: "Mecânico(a) De Veículos",
      label: "Mecânico(a) De Veículos"
    },
    {
      value: "Merceeiro(a)/vendeiro(a)",
      label: "Merceeiro(a)/vendeiro(a)"
    },
    {
      value: "Mergulhador(a) (Escafandrista)",
      label: "Mergulhador(a) (Escafandrista)"
    },
    {
      value: "Moendeiro(a)",
      label: "Moendeiro(a)"
    },
    {
      value: "Montador(a) De Móveis",
      label: "Montador(a) De Móveis"
    },
    {
      value: "Montador(a) E Instalador De Sistemas E Equipamentos De Iluminação E Sinalização Em Vias Públicas, Portos E Aeroportos",
      label: "Montador(a) E Instalador De Sistemas E Equipamentos De Iluminação E Sinalização Em Vias Públicas, Portos E Aeroportos"
    },
    {
      value: "Motoboy",
      label: "Motoboy"
    },
    {
      value: "Motorista (por aplicativo ou não) Independente",
      label: "Motorista (por aplicativo ou não) Independente"
    },
    {
      value: "Mototaxista",
      label: "Mototaxista"
    },
    {
      value: "Moveleiro(a)",
      label: "Moveleiro(a)"
    },
    {
      value: "Moveleiro(a) De Móveis Metálicos",
      label: "Moveleiro(a) De Móveis Metálicos"
    },
    {
      value: "Oleiro(a)",
      label: "Oleiro(a)"
    },
    {
      value: "Organizador(a) De Excursões Em Veículo Próprio, Municipal",
      label: "Organizador(a) De Excursões Em Veículo Próprio, Municipal"
    },
    {
      value: "Ourives",
      label: "Ourives"
    },
    {
      value: "Padeiro(a)",
      label: "Padeiro(a)"
    },
    {
      value: "Panfleteiro(a)",
      label: "Panfleteiro(a)"
    },
    {
      value: "Papeleiro(a)",
      label: "Papeleiro(a)"
    },
    {
      value: "Pastilheiro(a)",
      label: "Pastilheiro(a)"
    },
    {
      value: "Pedreiro",
      label: "Pedreiro"
    },
    {
      value: "Peixeiro(a)",
      label: "Peixeiro(a)"
    },
    {
      value: "Pintor(a) De Automóveis",
      label: "Pintor(a) De Automóveis"
    },
    {
      value: "Pintor(a) De Parede",
      label: "Pintor(a) De Parede"
    },
    {
      value: "Pipoqueiro(a)",
      label: "Pipoqueiro(a)"
    },
    {
      value: "Piscineiro(a)",
      label: "Piscineiro(a)"
    },
    {
      value: "Pizzaiolo(a) Em Domicílio",
      label: "Pizzaiolo(a) Em Domicílio"
    },
    {
      value: "Poceiro/Cisterneiro/Cacimbeiro",
      label: "Poceiro/Cisterneiro/Cacimbeiro"
    },
    {
      value: "Prestador(a) De Serviços de Colheita, Sob Contrato de Empreitada, Independente",
      label: "Prestador(a) De Serviços de Colheita, Sob Contrato de Empreitada, Independente"
    },
    {
      value: "Prestador(a) De Serviços de Poda, Sob Contrato de Empreitada, Independente",
      label: "Prestador(a) De Serviços de Poda, Sob Contrato de Empreitada, Independente"
    },
    {
      value: "Prestador(a) De Serviços de Preparação de Terrenos, Sob Contrato de Empreitada, Independente",
      label: "Prestador(a) De Serviços de Preparação de Terrenos, Sob Contrato de Empreitada, Independente"
    },
    {
      value: "Prestador(a) De Serviços de Roçagem, Destocamento, Lavração, Gradagem e Sulcamento, Sob Contrato de Empreitada, Independente",
      label: "Prestador(a) De Serviços de Roçagem, Destocamento, Lavração, Gradagem e Sulcamento, Sob Contrato de Empreitada, Independente"
    },
    {
      value: "Prestador(a) De Serviços de Semeadura, Sob Contrato de Empreitada, Independente",
      label: "Prestador(a) De Serviços de Semeadura, Sob Contrato de Empreitada, Independente"
    },
    {
      value: "Professor(a) Particular",
      label: "Professor(a) Particular"
    },
    {
      value: "Promotor(a) De Eventos",
      label: "Promotor(a) De Eventos"
    },
    {
      value: "Promotor(a) De Turismo Local",
      label: "Promotor(a) De Turismo Local"
    },
    {
      value: "Promotor(a) De Vendas",
      label: "Promotor(a) De Vendas"
    },
    {
      value: "Proprietário(a) De Albergue Não Assistencial",
      label: "Proprietário(a) De Albergue Não Assistencial"
    },
    {
      value: "Proprietário(a) De Bar E Congêneres com entretenimento",
      label: "Proprietário(a) De Bar E Congêneres com entretenimento"
    },
    {
      value: "Proprietário(a) De Bar E Congêneres sem entretenimento",
      label: "Proprietário(a) De Bar E Congêneres sem entretenimento"
    },
    {
      value: "Proprietário(a) De Camping",
      label: "Proprietário(a) De Camping"
    },
    {
      value: "Proprietário(a) De Cantinas",
      label: "Proprietário(a) De Cantinas"
    },
    {
      value: "Proprietário(a) De Carro De Som Para Fins Publicitários",
      label: "Proprietário(a) De Carro De Som Para Fins Publicitários"
    },
    {
      value: "Proprietário(a) De Casa De Chá",
      label: "Proprietário(a) De Casa De Chá"
    },
    {
      value: "Proprietário(a) De Casa De Sucos",
      label: "Proprietário(a) De Casa De Sucos"
    },
    {
      value: "Proprietário(a) De Casas De Festas E Eventos",
      label: "Proprietário(a) De Casas De Festas E Eventos"
    },
    {
      value: "Proprietário(a) De Estacionamento De Veículos",
      label: "Proprietário(a) De Estacionamento De Veículos"
    },
    {
      value: "Proprietário(a) De Fliperama",
      label: "Proprietário(a) De Fliperama"
    },
    {
      value: "Proprietário(a) De Hospedaria",
      label: "Proprietário(a) De Hospedaria"
    },
    {
      value: "Proprietário(a) De Lanchonete",
      label: "Proprietário(a) De Lanchonete"
    },
    {
      value: "Proprietário(a) De Pensão",
      label: "Proprietário(a) De Pensão"
    },
    {
      value: "Proprietário(a) De Restaurante",
      label: "Proprietário(a) De Restaurante"
    },
    {
      value: "Proprietário(a) De Sala De Acesso À Internet",
      label: "Proprietário(a) De Sala De Acesso À Internet"
    },
    {
      value: "Proprietário(a) De Salão De Jogos De Sinuca E Bilhar",
      label: "Proprietário(a) De Salão De Jogos De Sinuca E Bilhar"
    },
    {
      value: "Queijeiro(a)/ Manteigueiro(a)",
      label: "Queijeiro(a)/ Manteigueiro(a)"
    },
    {
      value: "Quitandeiro(a)",
      label: "Quitandeiro(a)"
    },
    {
      value: "Quitandeiro(a) Ambulante",
      label: "Quitandeiro(a) Ambulante"
    },
    {
      value: "Recarregador(a) De Cartuchos Para Equipamentos De Informática",
      label: "Recarregador(a) De Cartuchos Para Equipamentos De Informática"
    },
    {
      value: "Reciclador(a) De Borracha, Madeira, Papel E Vidro",
      label: "Reciclador(a) De Borracha, Madeira, Papel E Vidro"
    },
    {
      value: "Reciclador (a) De Materiais Metálicos, Exceto Alumínio",
      label: "Reciclador (a) De Materiais Metálicos, Exceto Alumínio"
    },
    {
      value: "Reciclador (a) De Materiais Plásticos",
      label: "Reciclador (a) De Materiais Plásticos"
    },
    {
      value: "Reciclador (a) De Sucatas De Alumínio",
      label: "Reciclador (a) De Sucatas De Alumínio"
    },
    {
      value: "Redeiro(a)",
      label: "Redeiro(a)"
    },
    {
      value: "Relojoeiro(a)",
      label: "Relojoeiro(a)"
    },
    {
      value: "Rendeiro(a)",
      label: "Rendeiro(a)"
    },
    {
      value: "Reparador (A) De Artigos De Tapeçaria",
      label: "Reparador (A) De Artigos De Tapeçaria"
    },
    {
      value: "Reparador(A) De Aparelhos E Equipamentos Para Distribuição E Controle De Energia Elétrica",
      label: "Reparador(A) De Aparelhos E Equipamentos Para Distribuição E Controle De Energia Elétrica"
    },
    {
      value: "Reparador(A) De Artigos E Acessórios Do Vestuário",
      label: "Reparador(A) De Artigos E Acessórios Do Vestuário"
    },
    {
      value: "Reparador(A) De Balanças Industriais E Comerciais",
      label: "Reparador(A) De Balanças Industriais E Comerciais"
    },
    {
      value: "Reparador(A) De Baterias E Acumuladores Elétricos, Exceto Para Veículos",
      label: "Reparador(A) De Baterias E Acumuladores Elétricos, Exceto Para Veículos"
    },
    {
      value: "Reparador(A) De Bicicleta",
      label: "Reparador(A) De Bicicleta"
    },
    {
      value: "Reparador(A) De Brinquedos",
      label: "Reparador(A) De Brinquedos"
    },
    {
      value: "Reparador(A) De Cordas, Velames E Lonas",
      label: "Reparador(A) De Cordas, Velames E Lonas"
    },
    {
      value: "Reparador(A) De Embarcações Para Esporte E Lazer",
      label: "Reparador(A) De Embarcações Para Esporte E Lazer"
    },
    {
      value: "Reparador(A) De Equipamentos Esportivos",
      label: "Reparador(A) De Equipamentos Esportivos"
    },
    {
      value: "Reparador(A) De Equipamentos Hidráulicos E Pneumáticos, Exceto Válvulas",
      label: "Reparador(A) De Equipamentos Hidráulicos E Pneumáticos, Exceto Válvulas"
    },
    {
      value: "Reparador(A) De Equipamentos Médico- Hospitalares Não-eletrônicos",
      label: "Reparador(A) De Equipamentos Médico- Hospitalares Não-eletrônicos"
    },
    {
      value: "Reparador(A) De Extintor De Incêndio",
      label: "Reparador(A) De Extintor De Incêndio"
    },
    {
      value: "Reparador(A) De Filtros Industriais",
      label: "Reparador(A) De Filtros Industriais"
    },
    {
      value: "Reparador(A) De Geradores, Transformadores E Motores Elétricos",
      label: "Reparador(A) De Geradores, Transformadores E Motores Elétricos"
    },
    {
      value: "Reparador(A) De Guarda Chuva E Sombrinhas Independente",
      label: "Reparador(A) De Guarda Chuva E Sombrinhas Independente"
    },
    {
      value: "Reparador(A) De Instrumentos Musicais",
      label: "Reparador(A) De Instrumentos Musicais"
    },
    {
      value: "Reparador(A) De Máquinas De Escrever, Calcular E De Outros Equipamentos Não-eletrônicos Para Escritório",
      label: "Reparador(A) De Máquinas De Escrever, Calcular E De Outros Equipamentos Não-eletrônicos Para Escritório"
    },
    {
      value: "Reparador(A) De Máquinas E Aparelhos De Refrigeração E Ventilação Para Uso Industrial E Comercial",
      label: "Reparador(A) De Máquinas E Aparelhos De Refrigeração E Ventilação Para Uso Industrial E Comercial"
    },
    {
      value: "Reparador(A) De Máquinas E Aparelhos Para A Indústria Gráfica",
      label: "Reparador(A) De Máquinas E Aparelhos Para A Indústria Gráfica"
    },
    {
      value: "Reparador(A) De Máquinas E Equipamentos Para A Indústria Da Madeira",
      label: "Reparador(A) De Máquinas E Equipamentos Para A Indústria Da Madeira"
    },
    {
      value: "Reparador(A) De Máquinas E Equipamentos Para A Indústria Têxtil, Do Vestuário, Do Couro E Calçados",
      label: "Reparador(A) De Máquinas E Equipamentos Para A Indústria Têxtil, Do Vestuário, Do Couro E Calçados"
    },
    {
      value: "Reparador(A) De Máquinas E Equipamentos Para Agricultura E Pecuária",
      label: "Reparador(A) De Máquinas E Equipamentos Para Agricultura E Pecuária"
    },
    {
      value: "Reparador(A) De Máquinas E Equipamentos Para As Indústrias De Alimentos, Bebidas E Fumo",
      label: "Reparador(A) De Máquinas E Equipamentos Para As Indústrias De Alimentos, Bebidas E Fumo"
    },
    {
      value: "Reparador(A) De Máquinas Motrizes Não-elétricas Lanchonetes",
      label: "Reparador(A) De Máquinas Motrizes Não-elétricas Lanchonetes"
    },
    {
      value: "Reparador(A) De Máquinas Para Bares e Lanchonetes Independente",
      label: "Reparador(A) De Máquinas Para Bares e Lanchonetes Independente"
    },
    {
      value: "Reparador(A) De Máquinas Para Encadernação Equipamentos Para Instalações Térmicas",
      label: "Reparador(A) De Máquinas Para Encadernação Equipamentos Para Instalações Térmicas"
    },
    {
      value: "Reparador(A) De Móveis",
      label: "Reparador(A) De Móveis"
    },
    {
      value: "Reparador(A) De Panelas (Paneleiro)",
      label: "Reparador(A) De Panelas (Paneleiro)"
    },
    {
      value: "Reparador(A) De Tanques, Reservatórios Metálicos E Caldeiras, Exceto Para Veículos",
      label: "Reparador(A) De Tanques, Reservatórios Metálicos E Caldeiras, Exceto Para Veículos"
    },
    {
      value: "Reparador(A) De Toldos E Persianas",
      label: "Reparador(A) De Toldos E Persianas"
    },
    {
      value: "Reparador(A) De Tonéis, Barris E Paletes De Madeira",
      label: "Reparador(A) De Tonéis, Barris E Paletes De Madeira"
    },
    {
      value: "Reparador(A) De Tratores Agrícolas",
      label: "Reparador(A) De Tratores Agrícolas"
    },
    {
      value: "Reparador(A) De Veículos De Tração Animal",
      label: "Reparador(A) De Veículos De Tração Animal"
    },
    {
      value: "Restaurador(A) De Instrumentos Musicais Históricos",
      label: "Restaurador(A) De Instrumentos Musicais Históricos"
    },
    {
      value: "Restaurador(A) De Jogos Acionados Por Moedas",
      label: "Restaurador(A) De Jogos Acionados Por Moedas"
    },
    {
      value: "Restaurador(A) De Livros",
      label: "Restaurador(A) De Livros"
    },
    {
      value: "Restaurador(A) De Obras De Arte",
      label: "Restaurador(A) De Obras De Arte"
    },
    {
      value: "Retificador(A) De Motores Para Veículos Automotores",
      label: "Retificador(A) De Motores Para Veículos Automotores"
    },
    {
      value: "Revelador(A) Fotográfico",
      label: "Revelador(A) Fotográfico"
    },
    {
      value: "Salgadeiro(A)",
      label: "Salgadeiro(A)"
    },
    {
      value: "Salineiro/extrator De Sal Marinho",
      label: "Salineiro/extrator De Sal Marinho"
    },
    {
      value: "Salsicheiro(A)/linguiceiro(A)",
      label: "Salsicheiro(A)/linguiceiro(A)"
    },
    {
      value: "Sapateiro(A)",
      label: "Sapateiro(A)"
    },
    {
      value: "Seleiro(A)",
      label: "Seleiro(A)"
    },
    {
      value: "Serigrafista",
      label: "Serigrafista"
    },
    {
      value: "Serigrafista Publicitário",
      label: "Serigrafista Publicitário"
    },
    {
      value: "Serralheiro(a), Exceto para Esquadrias, Sob Encomenda Ou Não, Independente",
      label: "Serralheiro(a), Exceto para Esquadrias, Sob Encomenda Ou Não, Independente"
    },
    {
      value: "Sintequeiro(A)",
      label: "Sintequeiro(A)"
    },
    {
      value: "Soldador(A) / Brasador(A)",
      label: "Soldador(A) / Brasador(A)"
    },
    {
      value: "Sorveteiro(A)",
      label: "Sorveteiro(A)"
    },
    {
      value: "Sorveteiro(A) Ambulante",
      label: "Sorveteiro(A) Ambulante"
    },
    {
      value: "Tanoeiro(A)",
      label: "Tanoeiro(A)"
    },
    {
      value: "Tapeceiro(A)",
      label: "Tapeceiro(A)"
    },
    {
      value: "Tatuador(A)",
      label: "Tatuador(A)"
    },
    {
      value: "Taxista",
      label: "Taxista"
    },
    {
      value: "Tecelão(ã)",
      label: "Tecelão(ã)"
    },
    {
      value: "Tecelão(ã) De Algodão",
      label: "Tecelão(ã) De Algodão"
    },
    {
      value: "Técnico(A) De Manutenção De Computador",
      label: "Técnico(A) De Manutenção De Computador"
    },
    {
      value: "Técnico(A) De Manutenção De Eletrodomésticos",
      label: "Técnico(A) De Manutenção De Eletrodomésticos"
    },
    {
      value: "Técnico(A) De Manutenção De Telefonia",
      label: "Técnico(A) De Manutenção De Telefonia"
    },
    {
      value: "Técnico(A) De Sonorização E De Iluminação",
      label: "Técnico(A) De Sonorização E De Iluminação"
    },
    {
      value: "Telhador(A)",
      label: "Telhador(A)"
    },
    {
      value: "Tintureiro(A)",
      label: "Tintureiro(A)"
    },
    {
      value: "Torneiro(A) Mecânico",
      label: "Torneiro(A) Mecânico"
    },
    {
      value: "Tosador(A) De Animais Domésticos",
      label: "Tosador(A) De Animais Domésticos"
    },
    {
      value: "Tosquiador(A)",
      label: "Tosquiador(A)"
    },
    {
      value: "Transportador(A) Aquaviário Para Passeios Turísticos",
      label: "Transportador(A) Aquaviário Para Passeios Turísticos"
    },
    {
      value: "Transportador(A) De Mudanças",
      label: "Transportador(A) De Mudanças"
    },
    {
      value: "Transportador(A) Escolar",
      label: "Transportador(A) Escolar"
    },
    {
      value: "Transportador(A) Intermunicipal Coletivo De Passageiros Sob Frete Em Região Metropolitana Independente",
      label: "Transportador(A) Intermunicipal Coletivo De Passageiros Sob Frete Em Região Metropolitana Independente"
    },
    {
      value: "Transportador(A) Intermunicipal E Interestadual De Travessia Por Navegação Fluvial",
      label: "Transportador(A) Intermunicipal E Interestadual De Travessia Por Navegação Fluvial"
    },
    {
      value: "Transportador(A) Marítimo De Carga",
      label: "Transportador(A) Marítimo De Carga"
    },
    {
      value: "Transportador(A) Municipal De Cargas Não Perigosas(Carreto)",
      label: "Transportador(A) Municipal De Cargas Não Perigosas(Carreto)"
    },
    {
      value: "Transportador(A) Municipal Coletivo De Passageiros Sob Frete Independente",
      label: "Transportador(A) Municipal Coletivo De Passageiros Sob Frete Independente"
    },
    {
      value: "Transportador(A) Municipal De Travessia Por Navegação",
      label: "Transportador(A) Municipal De Travessia Por Navegação"
    },
    {
      value: "Transportador(A) Municipal Hidroviário De Cargas",
      label: "Transportador(A) Municipal Hidroviário De Cargas"
    },
    {
      value: "Tricoteiro(A)",
      label: "Tricoteiro(A)"
    },
    {
      value: "Vassoureiro(A)",
      label: "Vassoureiro(A)"
    },
    {
      value: "Vendedor(A) Ambulante De Produtos Alimentícios",
      label: "Vendedor(A) Ambulante De Produtos Alimentícios"
    },
    {
      value: "Vendedor(A) De Aves Vivas, Coelhos E Outros Pequenos Animais Para Alimentação",
      label: "Vendedor(A) De Aves Vivas, Coelhos E Outros Pequenos Animais Para Alimentação"
    },
    {
      value: "Verdureiro",
      label: "Verdureiro"
    },
    {
      value: "Vidraceiro De Automóveis",
      label: "Vidraceiro De Automóveis"
    },
    {
      value: "Vidraceiro De Edificações",
      label: "Vidraceiro De Edificações"
    },
    {
      value: "Vinagreiro",
      label: "Vinagreiro"
    },
    {
      value: "Viveirista Independente",
      label: "Viveirista Independente"
    }
];

export const actingTypes = [
    {
      value: "Estabelecimento Fixo",
      label: "Estabelecimento Fixo"
    },
    {
      value: "Internet",
      label: "Internet"
    },
    {
      value: "Em Local Fixo Fora da Loja",
      label: "Em Local Fixo Fora da Loja"
    },
    {
      value: "Correio",
      label: "Correio"
    },
    {
      value: "Porta a Porta",
      label: "Porta a Porta"
    },
    {
      value: "Postos Móveis ou por Ambulante",
      label: "Postos Móveis ou por Ambulante"
    },
    {
      value: "Televenda",
      label: "Televenda"
    },
    {
      value: "Máquinas Automáticas",
      label: "Máquinas Automáticas"
    }
  ]