import React, { ReactNode, useState } from 'react';
import { Box, Chip, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { FieldInputProps } from 'formik';
import InputMask from 'react-input-mask';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Icon } from '@iconify/react';
import Autocomplete from '@mui/material/Autocomplete';
import { InputForm } from '../../types';

interface Props {
    inputs: InputForm[],
    touched: any,
    size?: "medium" | "small",
    values?: any,
    errors: any,
    after?: ReactNode,
    setFieldValue?(id: string, v: any): any,
    getFieldProps(n: string): FieldInputProps<any>, 
};

function access(obj: any, prop: string, defVal?: any) {
    if (typeof prop === 'string') {
        var objectNames = prop.split(".");

        var curObj = obj;
    
        for (var i = 0, len = objectNames.length; i < len; i++) {
            curObj = curObj[objectNames[i]];
            if (typeof curObj === 'undefined') {
                return defVal;
            }
        }
        return curObj;
    }
   
}

const FormBuilder = (props: Props) => {
    const [showPassword, setShowPassword] = useState(false);
    const {
        inputs,
        errors,
        values,
        setFieldValue,
        getFieldProps,
        size = 'small',
    } = props;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const [value, setValue] = useState('');

    const handleChange = (event: any) => {
        const formattedValue = formatCurrency(event.target.value);

        setValue(formattedValue);

        return formattedValue;
    };

    const formatCurrency = (value: any) => {
        const number = parseFloat(value.replace(/[^0-9]/g, ''));

        if (isNaN(number) || number === 0) {
            return '';
        }

        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }).format(number / 100);

        return formattedValue;
    };

    const handleChangeTaxpayer = (value: any) => {
        const inputCpf = value.replace(/\D/g, '');

        let formatted

        if (inputCpf.length > 3 && inputCpf.length <= 6) {
            formatted = inputCpf.replace(/^(\d{3})(\d{1,3})$/, '$1.$2');
        } else if (inputCpf.length > 6 && inputCpf.length <= 9) {
            formatted = inputCpf.replace(/^(\d{3})(\d{3})(\d{1,3})$/, '$1.$2.$3');
        } else if (inputCpf.length > 9) {
            formatted = inputCpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
        }

        return formatted;
    }

    return (
        <Grid container spacing={5}>
            {inputs?.map((input, i) => {
                const error = access(errors, input?.id);
                if (input?.type === 'autocomplete') {
                    return (
                        <Grid item key={`input-${input?.id}-${input?.type}`} { ...input.grid }>
                            <FormControl fullWidth>
                                <Typography fontSize={14} mb={1} fontWeight={600} color="#2D2935">
                                    {input?.label}
                                </Typography>
                                <Autocomplete
                                    size={size}
                                    multiple={input?.isMultiple}
                                    renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label={Array.isArray(values[input?.id]) ? values[input?.id].map((v: any) => v?.label).join(',') : values[input.id]?.label}
                                          placeholder={input?.placeholder || 'Selecione...'}
                                          name={input?.id}
                                          id={input?.id}
                                        />
                                    )}
                                    value={values[input?.id]}
                                    onChange={(event: any, value: any) => {
                                        setFieldValue && setFieldValue(input.id, value)
                                    }}
                                    getOptionLabel={(option: any) => option.label}
                                    options={input?.options || []}
                                    id={input?.id}
                                />
                                {Boolean(error) &&
                                    <FormHelperText color="red" style={{ color: 'red' }}>
                                        {error}
                                    </FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    )
                }

                if (input?.type === 'select') {
                    return (
                        <Grid item key={`input-${input?.id}-${input?.type}`} { ...input.grid }>
                            <FormControl fullWidth>
                                <Typography fontSize={14} mb={1} fontWeight={600} color="#2D2935">
                                    {input?.label}
                                </Typography>
                                <Select
                                    placeholder={input?.placeholder || 'Selecione...'}
                                    autoWidth
                                    size={size}
                                    multiple={input?.isMultiple}
                                    required={input?.required}
                                    MenuProps={{
                                        variant: 'menu',
                                        style: { height: 300, maxHeight: 300 },
                                    }}
                                    
                                    error={Boolean(error)}
                                    renderValue={input?.isMultiple ? (selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                          {selected.map((value: any) => (
                                            <Chip key={value} label={value} />
                                          ))}
                                        </Box>
                                      ) : undefined}
                                    {...getFieldProps(input?.id)}
                                >
                                    {input?.options?.map(op => (
                                        <MenuItem key={op?.value} value={op?.value}>{op?.label}</MenuItem>
                                    ))}
                                </Select>
                                {Boolean(error) &&
                                    <FormHelperText color="red" style={{ color: 'red' }}>
                                        {error}
                                    </FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                    )
                }

                if (input?.type === 'date') {
                    return (
                        <Grid item key={`input-${input?.id}-${input?.type}`} { ...input.grid }>
                            <Typography fontSize={14} mb={1} fontWeight={600} color="#2D2935">
                                {input?.label}
                            </Typography>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label={input?.label}
                                    onChange={(newValue) => {
                                        setFieldValue && setFieldValue(input?.id, newValue);
                                    }}
                                    
                                    inputFormat={'dd/MM/yyyy'}
                                    value={values[input?.id] ? values[input?.id] : null}
                                    renderInput={(params) => <TextField 
                                        fullWidth
                                        {...params}
                                    />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    )
                }

                if (input?.type === 'password') {
                    return (
                        <Grid item key={`input-${input?.id}-${input?.type}`} { ...input.grid }>
                            <Typography fontSize={14} mb={1} fontWeight={600} color="#2D2935">
                                {input?.label}
                            </Typography>
                            <TextField 
                                size={size}
                                fullWidth
                                type={showPassword ? 'text' : 'password'}
                                key={`${i}-${input.label}-${input.type}`}
                                placeholder={input.placeholder}
                                required={input.required}
                                error={Boolean(error)}
                                helperText={error}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowPassword} edge="end">
                                                <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                                {...getFieldProps(input.id)}
                            />
                        </Grid>
                    )
                }

                if (input?.currency) {
                    return (
                        <Grid item key={`input-${input?.id}-${input?.type}`} { ...input.grid }>
                            <Typography fontSize={14} mb={1} fontWeight={600} color="#2D2935">
                                {input?.label}
                            </Typography>
                            <TextField 
                                size={size}
                                fullWidth
                                type={input.type}
                                key={`${i}-${input.label}-${input.type}`}
                                placeholder={input.placeholder}
                                required={input.required}
                                error={Boolean(error)}
                                helperText={error}
                                {...getFieldProps(input.id)}
                                onChange={(event) => {
                                    const value = handleChange(event);
                                    setFieldValue && setFieldValue(input?.id, value)
                                }}
                            />
                        </Grid>
                    )
                }

                return (
                    <Grid item key={`input-${input?.id}-${input?.type}`} { ...input.grid }>
                        <Typography fontSize={14} mb={1} fontWeight={600} color="#2D2935">
                            {input?.label}
                        </Typography>
                        {input?.mask ? (
                            <InputMask
                                mask={input?.mask}
                                {...getFieldProps(input.id)}
                            >
                                {() =>   
                                    <TextField 
                                        type={input.type}
                                        size={size}
                                        required={input?.required}
                                        fullWidth
                                        key={`${i}-${input.label}-${input.type}`}
                                        placeholder={input.placeholder}
                                        error={Boolean(error)}
                                        helperText={error}
                                        {...getFieldProps(input.id)}
                                    />
                                }
                            </InputMask>
                        ) : (
                            <TextField 
                                size={size}
                                fullWidth
                                type={input.type}
                                key={`${i}-${input.label}-${input.type}`}
                                placeholder={input.placeholder}
                                required={input.required}
                                error={Boolean(error)}
                                helperText={error}
                                {...getFieldProps(input.id)}
                                value={values[input?.id] ? values[input?.id] : null}
                                onChange={(event) => {
                                    let value = event.target.value

                                    if (input?.id === 'cpf') {
                                        value = handleChangeTaxpayer(value);
                                    }

                                    setFieldValue && setFieldValue(input?.id, value)
                                }}
                            />
                        )}
                    </Grid>
                )
            })}
            {props?.after}
        </Grid>
    )

}

export default FormBuilder;