import axios, { AxiosResponse } from "axios";

export type AddressViaCep = {
    cep: string,
    logradouro: string,
    complemento: string,
    bairro: string,
    localidade: string,
    uf: string,
    ibge: string,
    gia: string,
    ddd: string,
    siafi: string,
};

export type ParsedAddress = {
    zipcode: string,
    city: string,
    district: string,
    street: string,
    state: string,
    complement: string,
};

export const requestViaCep = async (cep: string) => {
    try {
        const res = await axios.get<
            AddressViaCep,
            AxiosResponse<AddressViaCep>
        >(`https://viacep.com.br/ws/${cep}/json/`);

        return {
            zipcode: cep,
            city: res.data.localidade,
            complement: res.data.complemento,
            district: res.data.bairro,
            state: res.data.uf,
            street: res.data.logradouro,
        } as ParsedAddress;

    } catch (err) {}
};