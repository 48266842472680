import { Container, Typography, Grid, Card, Button } from "@mui/material"
import Qrcode from 'react-qr-code';
import { toast } from "react-toastify";
import dayjs from 'dayjs';
import { useEffect, useState } from "react";

export const PixPayment = (props: any) => {
    const { data } = props;

    const copy = (text: string) => {
        navigator.clipboard.writeText(text);
        toast('Pix copiado com sucesso!', { type: 'success' });
    }

    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <Container maxWidth={'md'} disableGutters>
            <Card style={{ margin: 10 }}>
                <Container style={{ padding: 32 }}>
                    <Typography fontSize={24} fontWeight={700}>
                        Olá{data?.name ? `, ${data?.name}!` : ''}
                    </Typography>
                    <Typography mt={4} fontWeight={500} fontSize={16} color={'#5D5F64'} align={isMobile ? 'center' : undefined}> Já recebemos a sua solicitação de abertura do MEI e seu processo será finalizado após o pagamento.</Typography>
                    <Typography mt={4} fontWeight={500} fontSize={16} color={'#5D5F64'} align={isMobile ? 'center' : undefined}> Confira os dados e a forma de pagamento solicitada: </Typography>

                    <div style={{ height: 1, width: '100%', backgroundColor: '#DDE4F6', marginTop: 20, marginBottom: 20  }}> </div>
                    <Grid container spacing={3}>
                        <Grid item xs justifyContent={'center'} alignItems={'center'}>
                            <Typography color={'#45464A'} fontSize={14} fontWeight={700}> Valor do Processo: </Typography>
                            <Typography color={'#45464A'} fontSize={16} mt={1.6} fontWeight={700} style={{ marginLeft: 10 }}>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data?.price)}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography color={'#45464A'} fontSize={14} fontWeight={700}>Data de vencimento: </Typography>
                            <Typography fontSize={16} mt={1.6} fontWeight={700} style={{ marginLeft: 10 }}>
                                {dayjs(data?.dueDate).format('DD/MM/YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography color={'#45464A'} fontSize={14} fontWeight={700}>Forma de pagamento: </Typography>
                            <Typography color={'#45464A'} fontSize={16} mt={1.6} fontWeight={700} style={{ marginLeft: 10 }}> PIX </Typography>
                        </Grid>
                    </Grid>
                    <div style={{ height: 1, width: '100%', backgroundColor: '#DDE4F6', marginTop: 20 }}> </div>

                    <Typography mt={4} color={'#313235'} fontWeight={700} fontSize={20}> Pague com PIX para continuar com o processo de abertura do seu MEI </Typography>
                    <Typography mt={4} color={'#313235'} fontWeight={400} fontSize={16}> Leia o <b> QRCode </b> abaixo com o aplicativo <b> do seu banco </b> e realize o pagamento do PIX: </Typography>

                    <div style={{ width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: 32 }}>
                        <Qrcode value={data?.key} style={{ justifySelf: 'center', width: 168, height: 168 }} />
                    </div>
                    <Typography mt={4} color={'#000000'} fontWeight={400} fontSize={16}> Copie o código pix abaixo e insira na opção <b> Pix Copia e Cola </b> no aplicativo <b>do seu banco</b> para realizar o pagamento do Pix: </Typography>

                    <div style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: '6%', display: 'flex', alignItems: 'center' }}>
                        <div style={{ height: 1, width: '40%', backgroundColor: '#DDE4F6' }}> </div>
                        <div style={{ backgroundColor: '#57D956', alignItems: 'center', justifyContent: 'center', display: 'flex', height: 46, width: 46, borderRadius: 50 }}>
                            <Typography color={'#313235'} fontSize={14} fontWeight={700}> OU </Typography>
                        </div>
                        <div style={{ height: 1, width: '40%', backgroundColor: '#DDE4F6' }}> </div>
                    </div>
                    <Typography mt={4} color={'#45464A'} fontWeight={700} fontSize={16}> Pix Copia e Cola </Typography>
                    <div style={{ height: '30%', padding: 10, marginTop: 4, width: '100%', border: '1px solid #DDE4F6' }}>
                        <Typography style={{ wordWrap: 'break-word' }}>
                            {data?.key}
                        </Typography>
                    </div>
                    <Button onClick={() => copy(data?.key)} style={{ marginTop: 24, backgroundColor: '#57D956', borderRadius: 5 }} variant="contained"> Copiar Pix Copia&Cola </Button>
                </Container>
            </Card >
        </Container >
    )
}